.windowSize {
    height: 100vh;
    width: 100vw;
    background-color: #595752;
}
.windowSize .kart-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5%; 
}
.kart1 {
    width: 33vw;
    height: 9vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    font-size: 3.15vw;
    background-color: white;
    color: black;
    font-weight: 800;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
}
.kart2 {
    width: 33vw;
    height: 9vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    font-size: 3.15vw;
    background-color: white;
    color: darkblue;
    font-weight: 500;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
}
.kart3 {
    width: 33vw;
    height: 9vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    font-size: 3.15vw;
    background-color: white;
    color: black;
    font-weight: 500;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
}
.kart4 {
    width: 33vw;
    height: 7.5vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: antiquewhite;
    color: black;
    font-size: 3.5vw;
    display: flex;
    justify-content: center; /* Dikey ortala */
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
}
.kart5 {
    width: 33vw;
    height: 48.6vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: #383E42;
    font-size: 12.8vw;
    text-shadow: 1px 1px 1px #c6c6c6;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Dikey ortala */
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
    transform: rotateY(10deg);
    transform: scale(1);
    color: orange;
}
.kart6 {
    width: 33vw;
    height: 23vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: #383E42;
    font-size: 12.8vw;
    text-shadow: 1px 1px 1px #c6c6c6;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Dikey ortala */
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
    color: orange;
}
