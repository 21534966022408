.windowSize {
    height: 100vh;
    width: 100vw;
    background-color: #595752;
}

    .windowSize .kart-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5%;
    }

.title1, .title3 {
    width: 33vw;
    height: 20vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: #D9D9D9;
    font-size: 5vw;
    font-weight: 500;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.title2 {
    width: 33vw;
    height: 20vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: #305496;
    font-size: 7vw;
    font-weight: 500;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}


.title4 {
    width: 33vw;
    height: 7vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: #F2F2F2;
    color: black;
    font-size: 2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
}

.title5 {
    width: 33vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    .title5 .half {
        width: 100%;
        height: 49%;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background-color: #D9D9D9;
        font-size: 6.4vw;
        text-shadow: 1px 1px 1px #c6c6c6;
        /*        font-weight: 350;
*/      display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
        color: orange;
        position: relative;
    
    }

    .title5 .half .box {
        width: 32vw;
        height: 22vh;
        border: 1px solid #ccc;
        border-radius: 20px;
        text-align: center;
        background-color: #7F7F7F;
        font-size: 12.8vw;
        text-shadow: 1px 1px 1px #c6c6c6;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
        color: orange;
    }

        .title5 .half span {
            font-size: 2vw;
            color: black;
            position: absolute;
            top: 5px;
        }

.title6 {
    width: 33vw;
    height: 70vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
    position: relative;
}

    .title6 .circle {
        width: 85%;
        height: 85%;
        border-radius: 50%;
        background-color: #4472C4;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10vw;
        color: orange;
        text-shadow: 1px 1px 1px #c6c6c6;
        font-weight: 400;
        color: white;
    }

.title7 {
    width: 33vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    .title7 .half {
        width: 100%;
        height: 49%;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background-color: #D9D9D9;
        font-size: 6.4vw;
        text-shadow: 1px 1px 1px #c6c6c6;
/*        font-weight: 350;
*/        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
        color: orange;
        position: relative;
    }

        .title7 .half .box2 {
            width: 32vw;
            height: 22vh;
            border: 1px solid #ccc;
            border-radius: 20px;
            text-align: center;
            background-color: #7F7F7F;
            font-size: 12.8vw;
            text-shadow: 1px 1px 1px #c6c6c6;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
            color: orange;
        }

        .title7 .half span {
            font-size: 2vw;
            color: black;
            position: absolute;
            top: 5px;
        }

.title8 {
    width: 33vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    .title8 .half {
        width: 100%;
        height: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background-color: #D9D9D9;
        font-size: 6.4vw;
        text-shadow: 1px 1px 1px #c6c6c6;
        /*        font-weight: 350;
*/      display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
        color: orange;
        position: relative;
    }

        .title8 .half .box {
            width: 32vw;
            height: 22vh;
            border: 1px solid #ccc;
            border-radius: 20px;
            text-align: center;
            background-color: #7F7F7F;
            font-size: 13vw;
            text-shadow: 1px 1px 1px #c6c6c6;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
            color: orange;
            margin-bottom: 50px
        }