.container {
    margin: 2px;
    overflow: hidden;
}

.LineInfo {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    position: relative;
    padding-top: 0; /* Başlık ile tablo arasındaki boşluğu kaldırmak için */
}

    .LineInfo .table-header {
        text-align: center;
        font-size: clamp(2vw, 3.5vw, 4.5vw); 
        font-weight: bold;
        color: black;
        background-color: #D9D9D9;
        width: 100%;
        margin: 0;
        padding: 0.5vw 0;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .LineInfo .tb_thread {
        font-weight: 200;
        text-shadow: 2px 2px 2px #c6c6c6;
        font-size: clamp(2vw, 2.5vw, 4vw); 
        width: 100%;
        vertical-align: middle;
        text-align: center;
    }

    .LineInfo .tb_Lines {
        font-weight: 500;
        text-shadow: 1px 1px 1px #c6c6c6;
        font-size:  6.3vw;
        width: 100%;
        vertical-align: middle;
        text-align: center;
    }

    .LineInfo .table {
        width: 100%; 
        margin: 0;
        table-layout: fixed;
        border-collapse: collapse; 
    }

        .LineInfo .table th, .LineInfo .table td {
            padding: clamp(0.3vw, 0.5vw, 0.8vw);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 0.85;
        }

        .LineInfo .table th {
            background-color: #D9D9D9;
            color: black;
        }

        .LineInfo .table td {
            background-color: #D9D9D9;
            color: black;
        }
