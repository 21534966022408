.container {
    margin: 2px;
    overflow: hidden;
}


.LineInfo {
    height: 100vh;
    width: 100vw;
}

    .LineInfo .tb_thread {
        font-weight: 200;
        text-shadow: 5px 5px 5px #c6c6c6;
        font-size: 3vw;
        margin: 0 auto;
        width: 55%;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;

    }

    .LineInfo .tb_Lines {
        font-weight: 500;
        text-shadow: 1px 1px 1px #c6c6c6;
        font-size: 6.5vw;
        margin: 0 auto;
        width: 100%;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;
        background-color: black;
    }
